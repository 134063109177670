<template>
  <div id="app">
    <router-view />
    <tab-bar v-if="!['theme', 'hall', 'passport'].includes($route.name)" />
  </div>
</template>

<script>
import TabBar from './components/tabBar.vue';
export default {
  name: "App",
  components: {
    TabBar,
  },
  watch: {
    $route(to) {
      // 路由变化的时候，让滚动数据变为0
      let point = to.hash
      if(point !== '#fixDetail' && point !== '#freeDetail' && point !== '#customizeDetail') {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      } 
    },
  },
};
</script>

<style>
#app {
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
  font-size: 16PX;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121933;
}
* {
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: #5169FE05;
}
a {
  color: #5169FE;
  text-decoration: none;
  cursor: pointer;
}
.content {
  width: 1200PX;
  margin: 0 auto;
}
.animate__fadeInRight, .animate__fadeInDown .animate__fadeInUp {
  animation-duration: 0.6s;
}
.animate__fadeIn {
  animation-duration: 0.6s;
}

input,
textarea {
  width: 416PX;
  height: 36PX;
  background: #ffffff;
  border: 1PX solid #e8e9e8;
  border-radius: 4PX;
  box-sizing: border-box;
  padding: 7PX 12PX;
  font-family: PingFangSC-Regular;
  font-size: 14PX;
  color: #707481;
}
input:focus,
textarea:focus, select:focus{ outline: 1PX solid #5169FE;}
textarea {
  height: 80PX;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #bbbeca;
} /*webkit 内核浏览器*/
input::-moz-placeholder, textarea::-moz-placeholder {
  color: #bbbeca;
} /*Mozilla Firefox 19+*/
input::-moz-placeholder, textarea:-moz-placeholder {
  color: #bbbeca;
} /*Mozilla Firefox 4 to 18*/
input::-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #bbbeca;
}
select {
  border: 1PX solid #E8E9E8;
  border-radius: 4PX;
  width: 176PX;
  height: 36PX;
  color: #121933;
}
@media screen and (max-width: 1000PX) {
  #app {
    min-width: 100vw;
    font-size: 24px;
    overflow: hidden;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;
    border-radius: 4PX;
  }
}
</style>
